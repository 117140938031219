import { sum_letters } from "../vendor/number-to-word.js";

import $ from "jquery";

export default function(config, setValue) {
  this.field = $("#" + config.field);
  this.target = $("#" + config.target);

  this.eventHandlers = function() {
    this.field.on(
      "change",
      function() {
        let value = this.field.val();

        if (value.length > 0) {
          let [integer, fractional] = value.split('.').map(item => item.replace(/\D+/g, ""))

          if (!fractional) {
            fractional = 0;
          }

          let word = sum_letters(integer) + ' руб. ' + sum_letters(fractional).toLowerCase() + ' коп.';
          setValue({ value: word, id: this.target.attr("id") });
        } else {
          setValue({ value: "", id: this.target.attr("id") });
        }
      }.bind(this)
    );
  };

  this.init = function() {
    this.eventHandlers();
  };

  return this;
}
